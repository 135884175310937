import FullCalendar from "@fullcalendar/react";
import { useRef, useState, useEffect } from "react";
import { CalendarStyle, CalendarToolbar } from "src/components/calendar";
import { Avatar, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { CommonHelper } from "src/utils/commonHelper";
import interactionPlugin from '@fullcalendar/interaction';
import useResponsive from 'src/hooks/useResponsive';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { postsModuleURL } from "src/services/urlService";
import HttpServices from "src/services/httpService";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CalendarUtil } from "./calendarUtil";
import CalendarGridView from "./post-view/calendar-grid-view.component";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const CalendarGrid = () => {
    const GetEventColor = (event) => {
        switch (event.status) {
            case 'SUCCESS':
                return "#4CAF50"; // Green
            case 'FAILED':
                return "#F44336"; // Red
            case 'YET_TO_DO':
                return "#CAF4FF"; // Blue
            case 'REJECTED':
                return "#FFD0EC"; // Pink
            case 'ELLAPSED':
                return "#9C27B0"; // Purple
            case 'PENDING':
                return "#F8FDCF"; // Yellow
            default:
                return "#FFEEF4"; // Grey
        }
    };

    const filterOption = {
        success: { Value: 'SUCCESS', Label: 'Success', Color: "#4CAF50" },
        all: { Value: 'ALL', Label: 'All', Color: "#FFEEF4" },
        failed: { Value: 'FAILED', Label: 'Failed', Color: "#F44336" },
        partialSuccess: { Value: 'PARTIAL_SUCCESS', Label: 'Partial Success', Color: "#FFEEF4" },
        yetToDo: { Value: 'YET_TO_DO', Label: 'Yet to Do', Color: "#CAF4FF" },
        rejected: { Value: 'REJECTED', Label: 'Rejected', Color: "#FFD0EC" },
        ellapsed: { Value: 'ELLAPSED', Label: 'Elapsed', Color: "#9C27B0" },
        pending: { Value: 'PENDING', Label: 'Pending for Approval', Color: "#F8FDCF" },
    };

    const theme = useTheme();
    const [date, setDate] = useState(new Date());
    const { showToast } = useToaster();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const calendarRef = useRef(null);
    const isDesktop = useResponsive('up', 'sm');
    const [events, setEvents] = useState([]);
    const [view, setView] = useState(isDesktop ? CalendarUtil.CalenderType.DayMonth : CalendarUtil.CalenderType.ListWeek);
    const currentDate = new Date();
    const [selectedCalendarDateTime, setSelectedCalendarDateTime] = useState(currentDate);
    const fromDateInitial = new Date(currentDate);
    fromDateInitial.setDate(fromDateInitial.getDate() - 30);
    const toDateInitial = new Date(currentDate);
    toDateInitial.setDate(toDateInitial.getDate() + 30);
    const [fromDate, setFromDate] = useState(fromDateInitial);
    const [toDate, setToDate] = useState(toDateInitial);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filter, setFilter] = useState(filterOption.all.Value);
    const [filteredEvents, setFilteredEvents] = useState(events);
    useEffect(() => {
        getEvents(fromDateInitial, toDateInitial)
    }, [])
    useEffect(() => {
        filterEvents();
    }, [filter, events]);
    const eventsSuccessCallback = (data, message) => {
        let eventData = [];
        if (data && data.length > 0)
            eventData = data.map((a) => {
                const startTime = new Date(CommonHelper.GetBrandTimeFromUTC(a.scheduled_time));
                // Add one minute to ensure the event stays within the same day
                const endTime = new Date(startTime.getTime() + 600); // Adding 1 minute
                return {
                    id: a.id,
                    userId: a.user_id,
                    title: a.description,
                    description: a.description,
                    textColor: a.color,
                    start: startTime,
                    end: endTime, // Add end time to prevent cross-day display
                    data: a,
                };
            });
        setEvents({
            events: eventData,
        });
    };
    const getEvents = (fromDate, toDate) => {
        let fDate = new Date(fromDate).toISOString();
        let tDate = new Date(toDate).toISOString();
        let postProps = {
            url: `${postsModuleURL.getCalendarEvents}${fDate}&to=${tDate}`,
            successCallback: eventsSuccessCallback,
        };
        HttpServices.Get(postProps);
    };
    const filterEvents = () => {
        if (filter === filterOption.all.Value) {
            setFilteredEvents(events);
        } else {
            const filtered = events?.events?.filter(event => event.data.status === filter);
            setFilteredEvents(filtered);
        }
    };
    const handlefilterEvents =(value)=>{
        setFilter(value)
    }
    const handleClickToday = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };
    const handleChangeView = (newView) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.changeView(newView);
            setView(newView);
        }
    };
    const scheduledEventSuccessCallback = (data, message) => {
        if (data && data.length > 0) loadPostData(data);
    };
    const scheduledEventFailureCallback = (message) => {
        showToast(message, 'e');
    };
    const handleSelectEvent = (arg) => {
        let postId = arg.event.id;
        let postProps = {
            url: postsModuleURL.scheduledEventInfo + postId,
            successCallback: scheduledEventSuccessCallback,
            failureCallback: scheduledEventFailureCallback,
        };
        HttpServices.Get(postProps);
    };
    const loadPostData = (response) => {
        let images = [];
        let imageData = response[0].media_url || response[0].media_urls;
        if (imageData) {
            images = imageData.split(',');
        }
        let serviceNames = CommonHelper.GetUniqueValueFromArray(response.map((a) => a.service_name));
        let serviceIds = CommonHelper.GetUniqueValueFromArray(response.map((a) => a.service_id));
        let sEvent = {
            message: response[0].text,
            scheduled_time: new Date(CommonHelper.GetLocalTimefromUTC(response[0].created_time)),
            pageIds: response.map((a) => a.connected_profile_id),
            serviceNames: serviceNames,
            serviceIds: serviceIds,
            privacy: response[0].privacy,
            images: images,
            schedule_id: response[0].schedule_post_id
        };
        setSelectedCalendarDateTime(new Date(CommonHelper.GetLocalTimefromUTC(response[0].created_time)));
        setSelectedEvent({ ...sEvent });
        handlePost(true);
    };

    const handlePost = (open) => {
        // alert("OPEN MODEL")
    };
    const handleClickDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            let fDate = calendarApi.getDate();
            let tDate = new Date(fDate);
            switch (view) {
                case CalendarUtil.CalenderType.DayMonth:
                    tDate.setDate(tDate.getDate() + 31);
                    fDate.setDate(fDate.getDate() - 31);
                    break;
                case CalendarUtil.CalenderType.ListWeek:
                    tDate.setDate(tDate.getDate() + 7);
                    fDate.setDate(fDate.getDate() - 7);
                    break;
                case CalendarUtil.CalenderType.TimeGridWeek:
                    tDate.setDate(tDate.getDate() + 7);
                    fDate.setDate(fDate.getDate() - 7);
                    break;
                case CalendarUtil.CalenderType.TimeGridDay:
                    tDate.setDate(tDate.getDate() + 1);
                    fDate.setDate(fDate.getDate() - 1);
                    break;
                default:
                    break;
            }
            tDate.setHours(0, 0, 0, 0);
            setDate(calendarApi.getDate());
            setFromDate(fDate);
            setToDate(tDate);
            getEvents(fDate, tDate);
        }
    };
    const handleClickDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            let fDate = calendarApi.getDate();
            let tDate = new Date(fDate);
            switch (view) {
                case CalendarUtil.CalenderType.DayMonth:
                    tDate.setDate(tDate.getDate() + 31);
                    fDate.setDate(fDate.getDate() - 31);
                    break;
                case CalendarUtil.CalenderType.ListWeek:
                    tDate.setDate(tDate.getDate() + 7);
                    fDate.setDate(fDate.getDate() - 7);
                    break;
                case CalendarUtil.CalenderType.TimeGridWeek:
                    tDate.setDate(tDate.getDate() + 7);
                    fDate.setDate(fDate.getDate() - 7);
                    break;
                case CalendarUtil.CalenderType.TimeGridDay:
                    tDate.setDate(tDate.getDate() + 1);
                    fDate.setDate(fDate.getDate() - 1);
                    break;
                default:
                    break;
            }
            tDate.setHours(0, 0, 0, 0);
            setDate(calendarApi.getDate());
            setFromDate(fDate);
            setToDate(tDate);
            getEvents(fDate, tDate);
        }
    };
    return (
        <Card>
            <CalendarStyle>
                <CalendarToolbar
                    date={CommonHelper.DateFormat(date)}
                    view={view}
                    filter={filter}
                    filterOption={filterOption}
                    onNextDate={handleClickDateNext}
                    onPrevDate={handleClickDatePrev}
                    onToday={handleClickToday}
                    onChangeView={handleChangeView}
                    handlefilterEvents={handlefilterEvents}
                />
                <FullCalendar
                    // timeZone={timeZone}
                    weekends
                    selectable
                    events={filteredEvents}
                    ref={calendarRef}
                    rerenderDelay={1}
                    initialDate={date}
                    initialView={view}
                    dayMaxEventRows={3}
                    // eventDisplay="block"
                    headerToolbar={false}
                    eventClick={handleSelectEvent}
                    height={isDesktop ? 'calc(94vh - 135px)' : 'calc(94vh - 200px)'}
                    plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                    eventContent={({ event }) => {
                        return <CalendarGridView view={view} event={event}></CalendarGridView>
                    }}

                />
            </CalendarStyle>
        </Card>
    )
}

export default CalendarGrid;