import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { Card, Stack, Typography, InputLabel, TextField } from '@mui/material';
import { FormProvider } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import ValidationTool from 'src/utils/validationHelper';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';

const WhiteLabel = () => {
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [domainUrl, setDomainUrl] = useState('');
  const [cNameKey, setCNameKey] = useState('');
  const [isDomainVerified, setIsDomainVerified] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const [isNextLoading, setNextLoading] = useState(false);
  const [isVerifyLoading, setVerifyLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showHelperText, setShowHelperText] = useState(true);
  const { showToast } = useToaster();

  useEffect(() => {
    generateFormModelSchema();
    getBrandingDetails();
  }, []);

  const generateFormModelSchema = () => {
    let fields = [ValidationTool.GenerateSchema('domainUrl', 'Domain Url', 'TEXT', true)];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };

  const getBrandingDetails = () => {
    let postProps = {
      url: userModuleURL.getBrandingDetails,
      successCallback: domainSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };

  const domainSuccessCallback = (data, message) => {
    setDomainDetails(data);
    setCNameKey(data.cname_key);
    setDomainUrl(data.domain_name);
    setIsDomainVerified(data.is_verified);
    if (data.domain_name !== '') {
      setShowDelete(true);
    }
    setNextLoading(false);
  };

  const failureCallback = (message) => {
    setNextLoading(false);
    setVerifyLoading(false);
    message && showToast(message, 'e');
  };

  const handleInputChange = (event) => {
    setDomainUrl(event.target.value);
  };

  const defaultValues = {
    domainUrl: domainUrl || '',
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const handleSubmitForm = () => {
    let userForm = {
      domainUrl,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.DomainValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else {
      setFormValidationErrors(formValidationErrors);
      setShowHelperText(false);
    }
  };

  const cNameSuccessCallback = (data, message) => {
    setNextLoading(false);
    setCNameKey(data.cname_key);
    setShowDelete(true);
  };

  const onSubmit = () => {
    setNextLoading(true);
    let domainProps = {
      domain_name: domainUrl,
    };
    let postProps = {
      url: userModuleURL.addCName,
      body: domainProps,
      successCallback: cNameSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const verifyCNameSuccessCallback = (data, message) => {
    data && getBrandingDetails();
    setVerifyLoading(false);
  };

  const handleVerify = () => {
    setVerifyLoading(true);
    let verifyProps = {
      domain_name: domainUrl,
    };
    let postProps = {
      url: userModuleURL.verifyCName,
      body: verifyProps,
      successCallback: verifyCNameSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const deleteCNameSuccessCallback = () => {
    getBrandingDetails();
    setShowDelete(false);
    setLoading(false);
  };

  const handleDeleteDomain = () => {
    setLoading(true);
    let deleteProps = {
      org_id: domainDetails.org_id,
    };
    let postProps = {
      url: userModuleURL.deleteCName,
      body: deleteProps,
      successCallback: deleteCNameSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const DomainHelperText = () => (
    <Typography component="span" variant="body2" fontWeight="bold">
      Example: app.brand2social.com
    </Typography>
  );

  return (
    <>
      <Helmet>
        <title>White Label</title>
      </Helmet>
      {domainDetails && (
        <>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
            <Card
              sx={{
                height: 'calc(100vh - 160px)',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
                Domain Mapping
              </Typography>
              <Stack alignItems="center">
                <TextField
                  value={domainUrl}
                  label="Domain URL"
                  variant="outlined"
                  disabled={isDomainVerified}
                  onChange={handleInputChange}
                  error={Boolean(formValidationErrors?.domainUrl)}
                  helperText={showHelperText && !domainUrl ? <DomainHelperText /> : formValidationErrors?.domainUrl}
                  sx={{
                    width: '50%',
                    '& .MuiFormHelperText-root': {
                      position: 'absolute',
                      bottom: '-20px',
                      left: 0,
                      marginLeft: '0px',
                    },
                    mb: 2,
                  }}
                />
              </Stack>
              {!isDomainVerified && (
                <>
                  {!cNameKey ? (
                    <Stack spacing={2} sx={{ mt: 2 }} alignItems="center">
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        loading={isNextLoading}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  ) : (
                    <Stack spacing={3} alignItems="center" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                      <Typography variant="body1" color="textSecondary">
                        Please copy the below keys and add them to your DNS settings:
                      </Typography>
                      <Typography variant="body2" sx={{ backgroundColor: 'secondary.main', p: 2, borderRadius: 1 }}>
                        <b>CNAME:</b> {domainUrl} {'  =====  '} <b>app.brand2social.com</b>
                      </Typography>
                      <Typography variant="body2" sx={{ backgroundColor: 'secondary.main', p: 2, borderRadius: 1 }}>
                        <b>TXT:</b> b2s.{domainUrl} {'  =====  '} <b>{cNameKey}</b>
                      </Typography>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={isVerifyLoading}
                        onClick={handleVerify}
                      >
                        Verify
                      </LoadingButton>
                    </Stack>
                  )}
                </>
              )}

              {showDelete && (
                <Stack spacing={2} alignItems="center">
                  <LoadingButton variant="contained" color="error" loading={isLoading} onClick={handleDeleteDomain}>
                    Delete Domain
                  </LoadingButton>
                </Stack>
              )}
            </Card>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default WhiteLabel;
