import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { Card, Stack, TextField, Typography, IconButton, InputAdornment, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';

const CustomEmail = () => {
  const { showToast } = useToaster();
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [notifyEmail, setNotifyEmail] = useState('');
  const [password, setPassword] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [hasCustomEmailConfig, setHasCustomEmail] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [testmailLoading, setTestmailLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCustomEmail();
  }, []);

  const fetchCustomEmail = () => {
    HttpServices.Get({
      url: userModuleURL.getCustomEmailDetails,
      successCallback: fetchEmailSuccessCallback,
      failureCallback: fetchEmailFailureCallback,
    });
  };

  const fetchEmailSuccessCallback = (data) => {
    if (data) {
      setHost(data.host || '');
      setPort(data.port || '');
      setNotifyEmail(data.username || '');
      setPassword(data.password || '');
      setReceiverEmail(data.receiverEmail || '');
      setHasCustomEmail(data.has_custom_email || false);
    }
  };

  const fetchEmailFailureCallback = () => {
    setSaveLoading(false);
  };

  const deleteConfig = () => {
    setDeleteLoading(true);
    HttpServices.Post({
      url: userModuleURL.deleteCustomEmailDetails,
      body: {},
      successCallback: deleteEmailConfigSuccessCallback,
      failureCallback: deleteEmailConfigFailureCallback,
    });
  };

  const deleteEmailConfigSuccessCallback = () => {
    setDeleteLoading(false);
    fetchCustomEmail();
  };

  const deleteEmailConfigFailureCallback = () => setDeleteLoading(false);

  const validateFields = () => {
    let newErrors = {};

    if (!host) newErrors.host = 'SMTP Host is required';
    if (!port) {
      newErrors.port = 'SMTP Port is required';
    } else if (isNaN(port) || port <= 0 || port > 65535) {
      newErrors.port = 'Port must be between 1 and 65535';
    }
    if (!notifyEmail) newErrors.notifyEmail = 'Notify Email is required';
    if (!password) newErrors.password = 'Password is required';
    if (!receiverEmail) newErrors.receiverEmail = 'Test Email is required';

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      showToast('Please fix errors before saving', 'e');
      return false;
    }

    return true;
  };

  const sendSampleEmail = () => {
    if (!validateFields()) return;

    setTestmailLoading(true);
    HttpServices.Post({
      url: userModuleURL.sendSampleCustomEmail,
      body: {
        host,
        port,
        notify_email: notifyEmail,
        notify_email_password: password,
        sender_email: receiverEmail,
      },
      successCallback: sendSampleEmailSuccessCallback,
      failureCallback: sendSampleEmailFailureCallback,
    });
  };

  const sendSampleEmailSuccessCallback = (_, message) => {
    showToast(message, 's');
    setTestmailLoading(false);
  };

  const sendSampleEmailFailureCallback = (message) => {
    showToast(message, 'e');
    setTestmailLoading(false);
  };

  const saveEmail = () => {
    if (!validateFields()) return;

    setSaveLoading(true);
    HttpServices.Post({
      url: userModuleURL.saveCustomEmailDetails,
      body: {
        host,
        port,
        notify_email: notifyEmail,
        notify_email_password: password,
        sender_email: receiverEmail,
      },
      successCallback: emailSuccessCallback,
      failureCallback: emailFailureCallback,
    });
  };

  const emailSuccessCallback = (_, message) => {
    showToast(message, 's');
    setSaveLoading(false);
    fetchCustomEmail();
  };

  const emailFailureCallback = (message) => {
    showToast(message, 'e');
    setSaveLoading(false);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Card
        sx={{
          height: 'calc(100vh - 160px)',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Box sx={{ p: 4, width: 700, mx: 'auto', mt: 5, boxShadow: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
            Custom Email Configuration
          </Typography>

          <Stack spacing={3}>
            <TextField
              label="SMTP Host"
              value={host}
              onChange={(e) => setHost(e.target.value)}
              fullWidth
              error={!!errors.host}
              helperText={errors.host}
            />
            <TextField
              label="SMTP Port"
              value={port}
              onChange={(e) => setPort(e.target.value)}
              fullWidth
              type="number"
              error={!!errors.port}
              helperText={errors.port}
            />
            <TextField
              label="Notification Email"
              value={notifyEmail}
              onChange={(e) => setNotifyEmail(e.target.value)}
              fullWidth
              error={!!errors.notifyEmail}
              helperText={errors.notifyEmail}
            />
            <TextField
              label="Notification Email App Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder="********"
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              width="100%"
              p={2}
              border="1px solid #ddd"
              borderRadius={2}
              boxShadow={1}
              bgcolor="white"
            >
              <TextField
                label="Test Email"
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                sx={{ flexGrow: 1 }}
                error={!!errors.receiverEmail}
                helperText={errors.receiverEmail}
              />
              <StyledComponent.B2SLoadingButton
                variant="outlined"
                loading={testmailLoading}
                onClick={sendSampleEmail}
                color="primary"
                sx={{ height: '56px', minWidth: '140px', marginTop: '0px' }}
              >
                Send Test Email
              </StyledComponent.B2SLoadingButton>
            </Box>

            <StyledComponent.B2SLoadingButton
              variant="outlined"
              loading={saveLoading}
              onClick={saveEmail}
              color="primary"
            >
              {hasCustomEmailConfig ? 'Update Config' : 'Save Config'}
            </StyledComponent.B2SLoadingButton>
            {hasCustomEmailConfig && (
              <>
                <StyledComponent.B2SLoadingButton
                  variant="outlined"
                  loading={deleteLoading}
                  onClick={deleteConfig}
                  color="error"
                >
                  Delete Config
                </StyledComponent.B2SLoadingButton>
              </>
            )}
          </Stack>
        </Box>
      </Card>
    </>
  );
};

export default CustomEmail;
