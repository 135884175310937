import { Card, Table, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import ScheduledReportGridBody from './PostGridBody.component';
import ScheduledReportGridHead from './PostGridHead.component';
import ScheduledReportPagination from './PostGridPagination.component';
import NoDraft from '../../../../assets/no-post.jpg';

const ScheduledReportGrid = ({ scheduledList,  handleReCallScheduleReport }) => {
    const theme = useTheme()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [height, setHeight] = useState(window.innerHeight - 150);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRequestSort = (event, property) => {
        // const isAsc = orderBy === property && order === 'asc';
        // let orderStr = isAsc ? 'desc' : 'asc'
       // handleReCallDraft(property, orderStr)
    };
    // const handleRecallDraftWithSort = () => {
    //     handleReCallDraft(orderBy, order)
    // };


    return (
        <>
            {scheduledList && scheduledList.length > 0 ? (
                <>
                    <DynamicHeightComponent >
                        <Scrollbar>
                            <TableContainer
                                sx={{
                                    maxHeight: 'calc(94vh - 111px)',
                                    minHeight: 'calc(94vh - 111px)',
                                    height: height - 60,
                                }}
                            >
                                <Table stickyHeader size='small'>
                                    <ScheduledReportGridHead
                                        scheduledList={scheduledList}
                                        // order={order}
                                        // orderBy={orderBy}
                                        handleRequestSort={handleRequestSort}
                                    >
                                    </ScheduledReportGridHead>
                                    <ScheduledReportGridBody
                                        scheduledList={scheduledList}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleReCallScheduleReport={handleReCallScheduleReport}
                                    >
                                    </ScheduledReportGridBody>
                                </Table>
                            </TableContainer>
                            <ScheduledReportPagination
                                scheduledList={scheduledList}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            ></ScheduledReportPagination>

                        </Scrollbar>
                    </DynamicHeightComponent>
                </>
            ) : (
                <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: height,minHeight: 'calc(100vh - 100px)', maxHeight: 'calc(100vh - 100px)' }}>
                    <img src={NoDraft} style={{ width: '50%', maxWidth: '50%'}} alt="No Schdeuled Reports" />
                </Card>
            )}
        </>
    );
};
export default ScheduledReportGrid;
