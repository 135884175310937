import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Input, Divider, IconButton, InputAdornment, Box, ListItem } from '@mui/material';
import EmojiPicker from 'src/components/EmojiPicker';
import { PlayArrowSharp as PlayArrowSharpIcon } from '@mui/icons-material';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import UploadMediaComponent from 'src/components/upload-media/uploadMedia.component';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Image from 'src/components/image/Image';
import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { m } from 'framer-motion';
import { LoadingButton } from '@mui/lab';


const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const InputArea = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export default function ChatMessageInput({ disabled, conversationId, onSend, isSendLoading }) {
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [type, setType] = useState('')
  const { showToast } = useToaster();

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleUploadMediaSuccessCallback = (mediaUrlList, type,file_size) => {
    if (file_size > 25 * 1024 * 1024) {
      showToast(`Media size must be less than 25 MB. Your Media is ${(file_size / 1024 / 1024).toFixed(2)} MB.`,'e');
  }
    setFileUrl(mediaUrlList);
    setType(type);
  };

  const handleUploadMediaFailureCallback = (message) => {
    message && showToast(message,'e');
  };

  const handleClear = () => {
    setMessage('');
    setFileUrl('');
    setFilePreview('');
  };

  const handleSend = () => {
    if (!message && !fileUrl) {
      return '';
    }
    if (onSend && conversationId) {
      onSend({
        id: conversationId,
        text: message,
        imageUrl: fileUrl,
        type: type
      });
    }
    return handleClear();
  };

  const handleEmoji = (emoji) => {
    setMessage(message + emoji);
  };

  const handleFiles = (event) => {
    let files = event.currentTarget.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);

      UploadMediaComponent({
        files,
        handleUploadMediaSuccessCallback,
        handleUploadMediaFailureCallback,
      });
    }
  };



  const handleRemovePreview = () => {
    setFilePreview('');
    setFileUrl('');
    setType('')
  };

  return (
    <RootStyle>
      {filePreview && (
        <ListItem
          // key={key}
          component={m.div}
          sx={{
            p: 0,
            m: 0.5,
            width: 100,
            height: 100,
            borderRadius: 1.25,
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-flex',
            opacity: 1,
            border: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          {/* <Image src={filePreview} alt={filePreview} ratio="1/1" /> */}
          {type === 'IMAGE' ? (
            <img src={fileUrl} alt={fileUrl} objectFit='cover' />
          ) : (
            <>
              <video src={fileUrl} alt={fileUrl} />
              <PlayArrowSharpIcon
                style={{
                  position: 'absolute', top: '50%', left: '50%',
                  transform: 'translate(-50%, -50%)', zIndex: 1,
                  borderRadius: '50%', background: 'black',
                  overflow: 'hidden', color: 'white',
                }}
              />
            </>)}
          <IconButton
            size="small"
            onClick={() => handleRemovePreview()}
            sx={{
              top: 6,
              p: '2px',
              right: 6,
              position: 'absolute',
              color: 'common.white',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
              },
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </IconButton>
        </ListItem>
      )}

      <InputArea>
        <Input
          disabled={fileUrl}
          fullWidth
          value={message}
          disableUnderline
          onKeyUp={handleKeyUp}
          onChange={(event) => setMessage(event.target.value)}
          placeholder="Type a message"
          startAdornment={
            <InputAdornment position="start">
              <EmojiPicker handleEmoji={handleEmoji} />
            </InputAdornment>
          }
          endAdornment={
            <Stack direction="row" spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
              <IconButton disabled={message} size="small" onClick={handleAttach}>
                <AddPhotoAlternateRoundedIcon sx={{ height: '22px', width: '22px' }} />
              </IconButton>
              <IconButton disabled={disabled} size="small">
                <MicRoundedIcon sx={{ height: '22px', width: '22px' }} />
              </IconButton>
            </Stack>
          }
        />

        <Divider orientation="vertical" flexItem />

        {/* <IconButton color="primary" disabled={!message && !fileUrl} onClick={handleSend} sx={{ mx: 1 }}>
          <SendRoundedIcon sx={{ height: '22px', width: '22px' }} />
        </IconButton> */}
        <LoadingButton
          variant="outlined"
          loading={isSendLoading}
          endIcon={<SendRoundedIcon />}
          size=""
          // onClick={() => { handlePostComment(comment, isParent) }}
          // disabled={!comment}
          onClick={() => handleSend()}
          disabled={!message && !fileUrl}
          sx={{
            border: 'none', '&.Mui-disabled': {
              border: 'none',
              opacity: 0.6
            },
            '&.MuiLoadingButton-root:hover': {
              border: 'none',
              background: 'none'
            }
          }}
        >
        </LoadingButton>
      </InputArea>

      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFiles} />
    </RootStyle>
  );
}
