import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  isShowCheckbox = true,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      // fontSize: 14,
    },
  }));
  return (
    <TableHead>
      <TableRow sx={{ height: 45 }}>
        {' '}
        {/* Adjust row height */}
        {isShowCheckbox && (
          <StyledTableCell padding="checkbox" sx={{ padding: '4px' }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </StyledTableCell>
        )}
        {headLabel.map((headCell) => (
          <StyledTableCell
            sx={{
              ...(headCell.width && { width: headCell.width }),
              padding: '6px', // Reduce padding
              lineHeight: 1.2, // Reduce line height
            }}
            key={headCell.id}
            align={headCell.alignRight ? 'right' : headCell?.alignCenter ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.hasSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
