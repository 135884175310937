import {
  MessageOutlined as MessageOutlinedIcon,
  Delete as DeleteIcon,
  ThumbUp as ThumbUpIcon,
  Send as SendIcon,
  Launch as LaunchIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'src/components/EmojiPicker';
import TextIconLabel from 'src/components/TextIconLabel';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import linkedin from '../../../assets/linked_in.svg';
import { LoadingButton } from '@mui/lab';
import LinkedInPostCard from '../publishedPostsPostCard/linkedInPostCard.component';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import Linkedinlike from '../../../assets/linkedin_like.svg';
import DeleteCommentIcon from '../../../assets/deletecomment.svg';
import LinkedinlikeIcon from '../../../assets/fblike.svg'

const LinkedInPagePostDetailView = ({
  pageId,
  postId,
  profile,
  height,
  message,
  images,
  name,
  thumbnail_url,
  video_url,
  image_url,
  created_time,
  is_liked,
  share_count,
  comment_count,
  like_count,
  publishedPosts,
  updatedPublishedPost,
}) => {
  const sideBarWidth = 800;
  const fileInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const childCommentInputRef = useRef(null);
  const [postDetails, setPostDetails] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [selectedComments, setSelectedComments] = useState([]);
  const [comment, setComment] = useState('');
  const [childComment, setChildComment] = useState('');
  const [childCommentId, setChildCommentId] = useState('');
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [totalComment, setTotalComment] = useState(0);
  const [count, setCount] = useState(0);
  const [loaderComments, setLoaderComments] = useState(false);
  const [loaderReplies, setLoaderReplies] = useState(false);
  const theme = useTheme();
  let Linkedin = theme.palette.preview.linkedin.color;
  let linkedinComment = theme.palette.preview.comments.linkedin;
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES
  );

  const setCommentToInitialState = () => {
    setComment('');
    setChildComment('');
  };

  useEffect(() => {
    getPostDetails();
  }, []);
  const linkedinPostDetailsSuccessCallback = (data, message) => {
    let pDetails = {
      id: data.id,
      image_url: image_url,
      message: message,
      like_count: data.like_count,
      comment_count: data.comment_count,
      share_count: data.share_count,
      is_liked: data.is_liked,
      timestamp: data.timestamp,
      link: data.link,
    };
    setPostDetails(pDetails);
    setCommentData(data.comments_data);
    setCount(data.comments_data.start);
    setTotalComment(data.total_posts);
    setLoader(false);
    setLoading(false);
  };

  const linkedinNextPostSuccessCallback = (data, message) => {
    let updatedComments = [...commentData.comments, ...data.comments_data.comments];
    let pagination = data.comments_data.start;
    setCommentData({
      comments: updatedComments,
      count: pagination,
    });
    setCount(data.comments_data.start);
    setLoaderComments(false);
    showToast(message, 's');
  };
  const LinkedinNextReplySuccessCallback = (data, message, commentId) => {
    let updatedComments = commentData.comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [...comment.replies, ...data.replies],
          start: data.start,
        };
      }
      return comment;
    });
    setCommentData({
      comments: updatedComments,
      pagination: commentData.pagination,
    });
    setLoaderReplies(false);
  };
  const linkedinPostDetailsFailureCallback = (message) => {
    setPostDetails(null);
    setCommentData(null);
    showToast(message, 'e');
    setLoader(false);
    setLoading(false);
    setLoaderComments(false);
    setLoaderReplies(false);
  };
  const getPostDetails = () => {
    setLoader(true);
    let getProps = {
      url: `${postsModuleURL.getLinkedinPostDetails}${pageId}&post_id=${postId}`,
      successCallback: linkedinPostDetailsSuccessCallback,
      failureCallback: linkedinPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextComments = () => {
    setLoaderComments(true);
    let getProps = {
      url: `${postsModuleURL.getLinkedinPostDetails}${pageId}&post_id=${postId}&after=${count.toString()}`,
      successCallback: linkedinNextPostSuccessCallback,
      failureCallback: linkedinPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextReplies = (comment) => {
    setLoaderReplies(true);
    const commentId = comment.id;
    let getProps = {
      url: `${postsModuleURL.getNextLinkedinPostDetails}${pageId}&post_id=${postId}&after=${comment.start}&comment_id=${commentId}`,
      successCallback: (data, message) => LinkedinNextReplySuccessCallback(data, message, commentId),
      failureCallback: linkedinPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const handleChangeComment = (e, isParent) => {
    if (isParent) setComment(e.target.value);
    else setChildComment(e.target.value);
  };
  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };
  const handleFocusComment = () => {
    commentInputRef.current?.focus();
  };
  const handleFocusChildComment = () => {
    childCommentInputRef.current?.focus();
  };
  const linkedinPostLikeSuccessCallback = (data, message) => {
    const updatedPostDetails = {
      ...postDetails,
      is_liked: !postDetails.is_liked,
      like_count: postDetails.is_liked ? postDetails.like_count - 1 : postDetails.like_count + 1,
    };
    const updatedPublishedPosts = publishedPosts.map((post) => {
      if (post.id === updatedPostDetails.id) {
        return {
          ...post,
          like_count: updatedPostDetails.like_count,
          is_liked: updatedPostDetails.is_liked,
        };
      }
      return post;
    });

    updatedPublishedPost(updatedPublishedPosts);
    setPostDetails(updatedPostDetails);
    showToast(message, 's');
  };

  const linkedinPostLikeFailureCallback = (data, message) => {
    showToast(message, 'e');
  };
  const handleLikePost = () => {
    let bodyProps = {
      post_id: postDetails.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.addLinkedinPostLike,
      body: bodyProps,
      successCallback: linkedinPostLikeSuccessCallback,
      failureCallback: linkedinPostLikeFailureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleUnLikePost = () => {
    let bodyProps = {
      post_id: postDetails.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.deleteLinkedinPostLike,
      body: bodyProps,
      successCallback: linkedinPostLikeSuccessCallback,
      failureCallback: linkedinPostLikeFailureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleLikeComment = (Id) => {
    const previousState = JSON.parse(JSON.stringify(commentData));
    const updatedComments = commentData.comments.map((comment) => {
      if (comment.id === Id) {
        return {
          ...comment,
          is_liked: !comment.is_liked,
          like_count: comment.is_liked ? comment.like_count - 1 : comment.like_count + 1,
        };
      } else {
        const updatedReplies = comment.replies.map((reply) => {
          if (reply.id === Id) {
            return {
              ...reply,
              is_liked: !reply.is_liked,
              like_count: reply.is_liked ? reply.like_count - 1 : reply.like_count + 1,
            };
          } else {
            return reply;
          }
        });

        return {
          ...comment,
          replies: updatedReplies,
        };
      }
    });

    setCommentData({
      ...commentData,
      comments: updatedComments,
    });

    let bodyProps = {
      post_id: postDetails.id,
      comment_id: Id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.addLinkedinCommentLike,
      body: bodyProps,
      failureCallback: (message) => {
        showToast(message, 'e');
        setCommentData(previousState);
      },
    };
    HttpServices.Post(postProps);
  };
  const handleUnLikeComment = (Id) => {
    const previousState = JSON.parse(JSON.stringify(commentData));
    const updatedComments = commentData.comments.map((comment) => {
      if (comment.id === Id) {
        return {
          ...comment,
          is_liked: !comment.is_liked,
          like_count: comment.is_liked ? comment.like_count - 1 : comment.like_count + 1,
        };
      } else {
        const updatedReplies = comment.replies.map((reply) => {
          if (reply.id === Id) {
            return {
              ...reply,
              is_liked: !reply.is_liked,
              like_count: reply.is_liked ? reply.like_count - 1 : reply.like_count + 1,
            };
          } else {
            return reply;
          }
        });

        return {
          ...comment,
          replies: updatedReplies,
        };
      }
    });

    setCommentData({
      ...commentData,
      comments: updatedComments,
    });
    let bodyProps = {
      post_id: postDetails.id,
      comment_id: Id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.deleteLinkedinCommentLike,
      body: bodyProps,
      failureCallback: (message) => {
        showToast(message, 'e');
        setCommentData(previousState);
      },
    };
    HttpServices.Post(postProps);
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
  };
  const postContent = () => {
    if (postDetails) {
      let cardDetails = {
        description: message,
        media_url: images.length > 0 ? images[0].toString() : thumbnail_url || video_url,
        name: name,
        image_url: image_url,
        created_time: created_time,
        like_count: postDetails.like_count,
        comment_count: postDetails.comment_count,
        share_count: postDetails.share_count,
        is_liked: postDetails.is_liked,
        profile,
        handleUnLikePost,
        handleLikePost,
        handleFocusComment
      };
      return <LinkedInPostCard {...cardDetails} />;
    } else return null;
  };
  const postComments = () => {
    return (
      <Stack spacing={1.5} sx={{ mt: 5, mb: 15 }}>
        {commentData &&
          commentData.comments &&
          commentData.comments.length > 0 &&
          commentData.comments.map((comment) => {
            return (
              <>
                <Card sx={{ m: '10px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                  {commentView(comment)}
                </Card>
                {selectedComments.some((a) => a.id === comment.id) && comment.replies && comment.replies.length > 0 && (
                  <>
                    {comment.replies.map((nestedComment) => (
                      <Card sx={{ marginLeft: '80px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                        {commentView(nestedComment, comment)}
                      </Card>
                    ))}
                    {comment.total_comment > comment.start && (
                      <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
                        {loaderReplies ? (
                          <ThreeDotLoader />
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: theme.palette.grey[500],
                            }}
                            onClick={() => getNextReplies(comment)}
                          >
                            Next Replies
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}

                {childCommentId && comment.id === childCommentId && writeContentView(childCommentId, childComment)}
              </>
            );
          })}
        {commentData.comments && totalComment > count && (
          <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
            {loaderComments ? (
              <ThreeDotLoader />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: theme.palette.grey[500],
                }}
                onClick={getNextComments}
              >
                Load More Comments
              </Typography>
            )}
          </Box>
        )}
      </Stack>
    );
  };

  const handleClickComment = (comment, parentComment) => {
    if (parentComment) {
      setChildCommentId(parentComment.id);
    } else {
      if (comment.comment_count === 0) {
        setChildCommentId(comment.id);
      } else {
        if (selectedComments.find((c) => c.id === comment.id)) {
          setChildCommentId(comment.id);
        }
        setSelectedComments([comment]);
        handleFocusComment();
      }
    }
  };

  const commentView = (comment, parentComment) => {
    return (
      <>
        <Stack
          key={comment.id}
          direction="row"
          spacing={2}
          style={{ marginLeft: 15, marginRight: 15, marginTop: 2, marginBottom: 2 }}
          disabled={!hasManageLikesCommentPermission}
        >
          {parentComment && <Divider orientation="vertical" flexItem sx={{ borderLeftWidth: 2 }} />}
          <Avatar
            alt={comment.username ? comment.username : comment.id}
            src={comment.profile_url ? comment.profile_url : comment.username}
            sx={{ bgcolor: theme.palette.primary.main }}
          />
          <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', boxShadow: 3, borderRadius: '10px' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
              <Typography variant="subtitle2">{comment.username}</Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {comment.text}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                {CommonHelper.DateTimeFromCurrentTime(
                  comment?.timestamp.includes('UTC')
                    ? CommonHelper.GetDateFormat(comment.timestamp)
                    : CommonHelper.GetLinkedinPreviewDateFormat(comment.timestamp)
                )}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                  color: 'text.disabled',
                }}
              >
                <StyledIconButton
                  onClick={() => {
                    hasManageLikesCommentPermission && (comment.like_count ? handleUnLikeComment(comment.id) : handleLikeComment(comment.id));
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.like_count ? comment.like_count : ''}
                    sx={{ typography: 'subtitle2' }}
                    icon={
                      comment.like_count > 0 ? (
                        <img src={Linkedinlike} style={{ width: 20, height: 20, transform: 'scaleX(-1)' }} />
                      ) : (
                        <img src={LinkedinlikeIcon} style={{ width: 18, height: 18, }} />
                      )
                    }
                  />
                </StyledIconButton>
                <StyledIconButton
                  onClick={() => {
                    handleClickComment(comment, parentComment);
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.comment_count ? comment.comment_count : ''}
                    sx={{ typography: 'subtitle2' }}
                    icon={
                      <MessageOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          color: comment.comment_count ? linkedinComment : 'theme.pallete.GREY[500]',
                        }}
                      />
                    }
                  />
                </StyledIconButton>
                <StyledIconButton
                  disabled={!hasManageLikesCommentPermission}
                  onClick={() => {
                    handleDeleteComment(comment, parentComment);
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    sx={{ typography: 'subtitle2' }}
                    icon={<img src={DeleteCommentIcon} style={{ width: 20, height: 20 }} />}
                  />
                </StyledIconButton>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </>
    );
  };
  const handleKeyUp = (event, comment, isParent) => {
    if (event.key === 'Enter' && comment !== "" && hasManageLikesCommentPermission) {
      handlePostComment(comment, isParent);
    }
  };
  const handleDeleteComment = (comment, parentComment) => {
    let deleteBody = {
      comment_id: comment.id,
      connected_profile_id: pageId,
      post_id: postId,
    };
    let postProps = {
      url: postsModuleURL.deleteLinkedinCommentAndMessage,
      body: deleteBody,
      failureCallback: (message) => linkedinDeleteFailureCallback(message, comment, parentComment),
    };
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      let index = parentData.replies.findIndex((a) => a.id === comment.id);
      parentData.replies.splice(index, 1);
      parentData.comment_count -= 1;
    } else {
      let index = commentData.comments.findIndex((a) => a.id === comment.id);
      commentData.comments.splice(index, 1);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count - 1,
      }));
    }
    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const handleEmoji = (emoji) => {
    setComment(comment + emoji);
  };

  const linkedinDeleteFailureCallback = (message, deletedComment, parentComment) => {
    showToast(message, 'e');
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      if (parentData) {
        parentData.replies.push(deletedComment);
        parentData.comment_count += 1;
      }
    } else {
      commentData.comments.push(deletedComment);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count + 1,
      }));
    }
    setCommentData({ ...commentData });
  };

  const writeContentView = (id, comment, isParent = false) => {
    return (
      <Card
        sx={{
          ...(!isParent && { boxShadow: 'none' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            ...(isParent && {
              position: 'fixed',
              bottom: '0px',
              padding: '25px',
              width: sideBarWidth,
              background: 'white',
            }),
          }}
          sx={{ marginRight: '12rem !important', marginLeft: isParent ? '0px' : '80px !important' }}
        >
          <Avatar
            src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
            alt={profile.profile_name ? profile.profile_name : profile.id}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: 40,
              width: 40,
              bgcolor: (theme) => theme.palette.primary.main,
            }}
            style={{ color: 'white' }}
          />
          <TextField
            fullWidth
            size="small"
            value={comment}
            disabled={!hasManageLikesCommentPermission}
            onKeyUp={(e) => {
              handleKeyUp(e, comment, isParent)
            }
            }
            inputRef={isParent ? commentInputRef : childCommentInputRef}
            placeholder="Write a comment…"
            onChange={(e) => {
              handleChangeComment(e, isParent);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmojiPicker handleEmoji={handleEmoji} />
                </InputAdornment>
              ),
            }}
            sx={{
              ml: 2,
              mr: 1,
              opacity: !hasManageLikesCommentPermission ? 0.5 : 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            endIcon={<SendIcon />}
            size=""
            onClick={() => {
              handlePostComment(comment, isParent);
            }}
            disabled={!comment || !hasManageLikesCommentPermission}
            sx={{
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
                opacity: 0.6,
              },
              '&.MuiLoadingButton-root:hover': {
                border: 'none',
                background: 'none',
              },
            }}
          ></LoadingButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Card>
    );
  };

  const handlePostComment = (comment, isParent) => {
    if (comment.length > 20000) {
      showToast(`Comment exceeds the maximum length of 20000 characters. Please shorten your comment. Current length: ${comment.length}`,'e');
      return;  
    }
    setLoading(true);
    let postCommentBody = {
      page_id: pageId,
      post_id: postId,
      message: comment,
    };
    let postChildCommentBody = {
      connected_profile_id: pageId,
      post_id: postId,
      comment_id: childCommentId,
      message: comment,
    };
    let postProps = {
      url: isParent ? postsModuleURL.addLinkedinComments : postsModuleURL.addLinkedinRepliesComments,
      body: isParent ? postCommentBody : postChildCommentBody,
      successCallback: (data, message) => getAfterAddCommentPostDetails(data, message, isParent),
      failureCallback: linkedinFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const getAfterAddCommentPostDetails = (data, message, isParent) => {
    message && showToast(message)
    if (isParent) {
      commentData.comments.push({
        id: data.id,
        text: data.text,
        is_liked: data.is_liked,
        like_count: 0,
        comment_count: 0,
        timestamp: data.timestamp,
        profile_url: profile?.profile_image,
        username: profile?.profile_name,
        total_comment: 0,
        start: 0,
        replies: []
      });
    } else {
      let parentComment = commentData.comments.find((c) => c.id === childCommentId);
      if (parentComment) {
        parentComment.replies.push({
          id: data.id,
          text: data.text,
          is_liked: data.is_liked,
          timestamp: data.timestamp,
          profile_url: profile?.profile_image,
          username: profile?.profile_name,
          like_count: 0
        });
        parentComment.comment_count += 1;
      }
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count + 1,
      }));
    }
    setCommentData({ ...commentData });
    setLoading(false);
    setCommentToInitialState();
  };

  const linkedinFailureCallback = (message) => {
    message && showToast(message);
    setLoading(false);
  };
  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Box sx={{ position: 'fixed', width: sideBarWidth, zIndex: 1000, background: 'white' }}>
          <Grid alignItems="center" display="flex" sx={{ p: 1 }}>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 800,
                marginLeft: 3,
                color: Linkedin,
              }}
            >
              Linked
            </Typography>
            <img alt="LinekedIn_Icon" width={40} height={40} sx={{ fontSize: 22 }} src={linkedin} />
            <a href={postDetails?.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
              <LaunchIcon sx={{ fontSize: '2rem', mr: 2, color: 'primary.main' }} />
            </a>
          </Grid>
          <Divider />
        </Box>
        <Box sx={{ mt: '5.6rem' }}>
          {showLoader ? (
            <Spinner height={height} />
          ) : (
            <>
              <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
                {postContent()}
              </Grid>
              {postComments()}
              {writeContentView(pageId, comment, true)}
            </>
          )}
        </Box>
      </StyledComponent.StyledStack>
    </>
  );
};

//Styled Components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px', // for screens wider than 600px
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '24px', // for screens wider than 960px
    },
  },
}));

export default LinkedInPagePostDetailView;
