import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  TextField,
  Stack,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';
import { FormProvider, RHFUploadAvatar } from '../hook-form';
import { imageModeuleUrl } from 'src/services/urlService';
import { userModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import LinearProgressBar from '../progress-bar/progressBar.component';
import { LoadingButton } from '@mui/lab';
import { CommonHelper } from 'src/utils/commonHelper';

const WorkPlaceBox = ({ handleClose, handleSuccess }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [fileUploadprogress, setFileUploadprogress] = useState(0);
  const [brandName, setBrandName] = useState('');
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [description, setDescription] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [timeZone,setTimeZone] = useState("UTC");
  const { showToast } = useToaster();

  const maxFileSizeInBytes = 10485760;
  const defaultValues = {
    imageUrl: imageUrl || '',
    description: description || '',
    brandName: brandName || '',
  };

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(localTimeZone) setTimeZone(localTimeZone);
    generateFormModelSchema();
  }, []);

  const generateFormModelSchema = () => {
    let fields = [ValidationTool.GenerateSchema('brandName', 'Brand Name', 'TEXT', true)];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const successCallback = (data, message) => {
    setLoading(false);
    message && showToast(message, 's');
    handleSuccess();
  };

  const failureCallback = (message) => {
    setLoading(false);
    showToast(message, 'e');
  };
  const handleSubmitForm = () => {
    let userForm = {
      brandName: brandName,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setLoading(true);
      setFormValidationErrors({});
      createNewBrand();
    } else setFormValidationErrors(formValidationErrors);
  };
  const createNewBrand = () => {
    let newBrand = {
      brand_name: brandName,
      description: description,
      brand_image: imageUrl,
      timezone: timeZone

    };
    let postProps = {
      url: userModuleURL.createNewBrand,
      body: newBrand,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'BrandName':
        setBrandName(value);
        break;
      case 'Description':
        setDescription(value);
        break;
      default:
        break;
    }
  };
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFileUpload(file);
  };

  const handleDeleteBrandImage = () => {
    setImageUrl('');
  };

  const fileUploadSuccessCallback = (data, message) => {
    setImageUrl(data.media_data[0].media_url);
  };
  const handleFileUpload = (file) => {
    let isFileInCorrectFormat = CommonHelper.SupportedImageFormat.some((a) => a.includes(file.type));
    if (isFileInCorrectFormat) {
      if (file.size < maxFileSizeInBytes) {
        setFileUploadprogress(1);
        let formData = new FormData();
        formData.append('fileupload', file);
        axios
          .post(imageModeuleUrl.uploadMedia, formData, {
            headers: HttpServices.GetRequestHeader('form', true),
            onUploadProgress: (data) => {
              let loadedProgress = Math.round((100 * data.loaded) / data.total);
              setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
            },
          })
          .then((result) => result)
          .then((response) => {
            setFileUploadprogress(100);
            if (response.status === 200 && response.data.http_code === 200)
              fileUploadSuccessCallback(response.data.data, 'File Uploaded Successfully');
            else showToast('Something went wrong. Please contact our technical team', 'e');
            setFileUploadprogress(0);
          })
          .catch((error) => {
            setFileUploadprogress(0);
            showToast(error.toString(), 'e');
          });
      } else showToast('Maximum file size for image is 10MB. Please select image below 10 MB', 'e');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  return (
    <Dialog open maxWidth="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <DialogTitle>
          <Typography variant="h4"> Create Brand</Typography>
        </DialogTitle>
        <DialogContent>
          <Card sx={{ p: 10, textAlign: 'center' }}>
            <Box position="relative" display="inline-block">
              <RHFUploadAvatar
                name="imageUrl"
                accept="image/*"
                onDrop={handleDrop}
                handleDeleteProfiles={handleDeleteBrandImage}
                url={imageUrl}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of 5Mb
                  </Typography>
                }
              />
            </Box>
          </Card>
          {fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
          <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
            <TextField
              id="outlined-basic"
              label="Brand Name"
              name="BrandName"
              value={brandName}
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              error={formValidationErrors?.brandName}
              helperText={formValidationErrors?.brandName}
            />
            <TextField
              id="outlined-basic"
              label="Description"
              name="Description"
              value={description}
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" loading={isLoading} type="submit">
            Create Brand
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default WorkPlaceBox;
