import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/hook-form';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';
import UserInvite from '../../assets/icons/user_invite.svg';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(8, 0),
}));

const CreateUserFromToken = () => {
  
  const [password, setPassword] = useState('');
  const popupWindowURL = new URL(window.location.href);
  const token = popupWindowURL.searchParams.get('token');
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const { showToast } = useToaster();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    CommonHelper.ClearAllLocalStorage();
    generateFormModelSchema();
  }, []);

  const generateFormModelSchema = () => {
    let fields = [ValidationTool.GenerateSchema('password', 'Password', 'TEXT', true)];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };
  const successCallback = (data, message) => {
    setLoading(false);
    CommonHelper.AppLoginCall(data, true);
  };
  const failureCallback = (message) => {
    setLoading(false);
    showToast(message, 'e');
  };
  const CreateUserFromToken = (userForm) => {
    let postProps = {
      url: userModuleURL.createUserFromToken,
      body: userForm,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleSubmitForm = () => {
    let userForm = {
      password: password,
      token: token,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setLoading(true);
      setFormValidationErrors({});
      CreateUserFromToken(userForm);
    } else setFormValidationErrors(formValidationErrors);
  };
  const handleInputChange = (event) => {
    setPassword(event.target.value);
  };
  const defaultValues = {
    password: password,
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  return token ? (
    <>
      <Helmet>
        <title>Verify User</title>
      </Helmet>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <RootStyle>
          <Container>
            <Box sx={{ maxWidth: 680, mx: 'auto' }}>
              <Card sx={{ py: 3, px: 3, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                  <img
                    src={UserInvite}
                    style={{ height: 350 }}
                  /></Box>
                <Typography variant="h3" paragraph>
                  Create your Password?
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                  Please set up your account by creating a password, and you will be able to access our brand.
                </Typography>
                <TextField
                  name="Enter a Password"
                  label="Enter a Password"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleInputChange}
                  error={formValidationErrors?.password}
                  helperText={formValidationErrors?.password}
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton type="submit" variant="contained" size="large" fullWidth loading={isLoading}>
                  Submit
                </LoadingButton>
              </Card>
            </Box>
          </Container>
        </RootStyle>
      </FormProvider>
    </>
  ) : null;
};
export default CreateUserFromToken;
