import {
  MessageOutlined as MessageOutlinedIcon,
  Delete as DeleteIcon,
  ThumbUp as ThumbUpIcon,
  Send as SendIcon,
  Launch as LaunchIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'src/components/EmojiPicker';
import TextIconLabel from 'src/components/TextIconLabel';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import YoutubeIcon from '../../../assets/youtube.png';
import { LoadingButton } from '@mui/lab';
import YoutubePostCard from '../publishedPostsPostCard/youtubePostCard.component';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import YTReactionLikeIcon from '../../../assets/icons/facebook-reaction-like.svg';
import DeleteCommentIcon from '../../../assets/deletecomment.svg';
import YTlikeIcon from '../../../assets/fblike.svg'

const YoutubePostDetailView = ({ pageId, postId, profile, height }) => {
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES
  );
  const sideBarWidth = 800;
  const fileInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const childCommentInputRef = useRef(null);
  const [postDetails, setPostDetails] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [selectedComments, setSelectedComments] = useState([]);
  const [comment, setComment] = useState('');
  const [childComment, setChildComment] = useState('');
  const [childCommentId, setChildCommentId] = useState('');
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const [loaderComments, setLoaderComments] = useState(false);
  const [loaderReplies, setLoaderReplies] = useState(false);
  const theme = useTheme();
  let youtubeComment = theme.palette.preview.comments.youtube;

  const setCommentToInitialState = () => {
    setComment('');
    setChildComment('');
  };
  useEffect(() => {
    getPostDetails();
  }, []);
  const youtubePostDetailsSuccessCallback = (data, message) => {
    let pDetails = {
      id: data.id,
      image_url: data.image_url,
      message: data.message,
      like_count: data.like_count,
      comment_count: data.comment_count,
      view_count: data.view_count,
      is_liked: data.is_liked,
      created_time: data.created_time,
      link: data.link,
    };
    setPostDetails(pDetails);
    setCommentData(data.comments_data);
    setPagination(data.comments_data.pagination);
    setLoader(false);
    setLoading(false);
  };
  const youtubeNextPostSuccessCallback = (data, message) => {
    let updatedComments = [...commentData.comments, ...data.comments_data.comments];
    let pagination = data.comments_data.pagination;
    setCommentData({
      comments: updatedComments,
      pagination: pagination,
    });
    setPagination(data.comments_data.pagination);
    setLoaderComments(false);
    showToast(message, 's');
  };
  const youtubeNextReplySuccessCallback = (data, message, commentId) => {
    let updatedComments = commentData.comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [...comment.replies, ...data.replies],
          pagination: data.pagination,
        };
      }
      return comment;
    });
    setCommentData({
      comments: updatedComments,
      pagination: commentData.pagination,
    });
    setLoaderReplies(false);
  };
  const youtubePostDetailsFailureCallback = (message) => {
    setPostDetails(null);
    setCommentData(null);
    showToast(message, 'e');
    setLoader(false);
    setLoading(false);
    setLoaderComments(false);
    setLoaderReplies(false);
  };
  const getPostDetails = () => {
    setLoader(true);
    let getProps = {
      url: `${postsModuleURL.getYoutubePostDetails}${pageId}&post_id=${postId}`,
      successCallback: youtubePostDetailsSuccessCallback,
      failureCallback: youtubePostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextComments = () => {
    setLoaderComments(true);
    let getProps = {
      url: `${postsModuleURL.getYoutubePostDetails}${pageId}&post_id=${postId}&after=${pagination}`,
      successCallback: youtubeNextPostSuccessCallback,
      failureCallback: youtubePostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextReplies = (comment) => {
    setLoaderReplies(true);
    const commentId = comment.id;
    let getProps = {
      url: `${postsModuleURL.getYoutubeNextReply}${pageId}&comment_id=${commentId}&after=${comment.pagination}`,
      successCallback: (data, message) => youtubeNextReplySuccessCallback(data, message, commentId),
      failureCallback: youtubePostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const handleChangeComment = (e, isParent) => {
    if (isParent) setComment(e.target.value);
    else setChildComment(e.target.value);
  };
  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };
  const handleFocusComment = () => {
    commentInputRef.current?.focus();
  };
  const handleFocusChildComment = () => {
    childCommentInputRef.current?.focus();
  };
  const postlikeSuccessCallback = (data, message) => {
    showToast(message);
    if (!postDetails.is_liked) {
      postDetails.like_count = postDetails.like_count + 1;
      postDetails.is_liked = true;
      setPostDetails({ ...postDetails });
    }
  };
  const postUnlikeSuccessCallback = (data, message) => {
    showToast(message);
    if (postDetails.like_count > 0 && postDetails.is_liked) {
      postDetails.like_count = postDetails.like_count - 1;
      postDetails.is_liked = false;
      setPostDetails({ ...postDetails });
    }
  };
  const handleLikePost = (post) => {
    let bodyProps = {
      rating: 'like',
      video_id: postDetails.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.addRemoveLikeYoutube,
      body: bodyProps,
      successCallback: postlikeSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleUnLikePost = () => {
    let bodyProps = {
      rating: 'dislike',
      video_id: postDetails.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.addRemoveLikeYoutube,
      body: bodyProps,
      successCallback: postUnlikeSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
  };
  const postContent = () => {
    if (postDetails) {
      let cardDetails = {
        media_url: postDetails.image_url,
        description: postDetails.message,
        like_count: postDetails.like_count,
        comment_count: postDetails.comment_count,
        view_count: postDetails.view_count,
        is_liked: postDetails.is_liked,
        profile,
        handleUnLikePost,
        handleLikePost,
        handleFocusComment,
      };
      return <YoutubePostCard {...cardDetails} />;
    } else return null;
  };
  const postComments = () => {
    return (
      <Stack spacing={1.5} sx={{ mt: 5, mb: 15 }}>
        {commentData &&
          commentData.comments &&
          commentData.comments.length > 0 &&
          commentData.comments.map((comment) => {
            return (
              <>
                <Card sx={{ m: '10px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                  {commentView(comment)}
                </Card>
                {selectedComments.some((a) => a.id === comment.id) && comment.replies && comment.replies.length > 0 && (
                  <>
                    {comment.replies.map((nestedComment) => (
                      <Card sx={{ marginLeft: '80px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                        {commentView(nestedComment, comment)}
                      </Card>
                    ))}
                    {comment.pagination && (
                      <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
                        {loaderReplies ? (
                          <ThreeDotLoader />
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: theme.palette.grey[500],
                            }}
                            onClick={() => getNextReplies(comment)}
                          >
                            Next Replies
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}

                {childCommentId && comment.id === childCommentId && writeContentView(childCommentId, childComment)}
              </>
            );
          })}
        {pagination && (
          <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
            {loaderComments ? (
              <ThreeDotLoader />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: theme.palette.grey[500],
                }}
                onClick={getNextComments}
              >
                Load More Comments
              </Typography>
            )}
          </Box>
        )}
      </Stack>
    );
  };

  const handleClickComment = (comment, parentComment) => {
    if (parentComment) {
      setChildCommentId(parentComment.id);
    } else {
      if (comment.comment_count === 0) {
        setChildCommentId(comment.id);
      } else {
        if (selectedComments.find((c) => c.id === comment.id)) {
          setChildCommentId(comment.id);
        }
        setSelectedComments([comment]);
        handleFocusComment();
      }
    }
  };

  const commentView = (comment, parentComment) => {
    return (
      <>
        <Stack key={comment.id} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15, marginTop: 2, marginBottom: 2 }}>
          {parentComment && <Divider orientation="vertical" flexItem sx={{ borderLeftWidth: 2 }} />}
          <Avatar
            alt={comment.author_name ? comment.author_name : comment.id}
            src={comment.author_profile_image_url ? comment.author_profile_image_url : comment.author_name}
            sx={{ bgcolor: theme.palette.primary.main }}
          />
          <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', boxShadow: 3, borderRadius: '10px' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
              <Typography variant="subtitle2">{comment.author_name}</Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {comment.message}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                {CommonHelper.DateTimeFromCurrentTime(comment.created_time)}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                  color: 'text.disabled',
                }}
              >
                <StyledIconButton >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.like_count ? comment.like_count : ''}
                    sx={{ typography: 'subtitle2'}}
                    icon={<img src={YTlikeIcon} style={{ width: 18, height: 18 }} />}
                  />
                </StyledIconButton>
                <StyledIconButton
                  onClick={() => {
                    handleClickComment(comment, parentComment);
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.comment_count ? comment.comment_count : ''}
                    sx={{ typography: 'subtitle2' }}
                    icon={
                      <MessageOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          color: comment.comment_count ? youtubeComment : 'theme.pallete.GREY[500]',
                        }}
                      />
                    }
                  />
                </StyledIconButton>
                <StyledIconButton disabled={!hasManageLikesCommentPermission}  onClick={() => {
                  handleDeleteComment(comment, parentComment);
                }}>
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    sx={{ typography: 'subtitle2' }}
                    icon={ <img src={DeleteCommentIcon} style={{ width: 20, height: 20 }} />}

                  />
                </StyledIconButton>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </>
    );
  };

  const handleDeleteComment = (comment, parentComment) => {
    let deleteBody = {
      id: comment.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.deleteYoutubeCommentAndMessage,
      body: deleteBody,
      failureCallback: (message) => youtubeDeleteFailureCallback(message,comment, parentComment),
    };
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      if (parentData) {
        let index = parentData.replies.findIndex((a) => a.id === comment.id);
        if (index !== -1) {
          parentData.replies.splice(index, 1);
          parentData.comment_count -= 1;
        }
      }
    } else {
      let index = commentData.comments.findIndex((a) => a.id === comment.id);
      if (index !== -1) {
        commentData.comments.splice(index, 1);
      }
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count - 1,
      }))
    }
    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const handleEmoji = (emoji) => {
    setComment(comment + emoji);
  };

  const youtubeDeleteSuccessCallback = (data, message) => {
    showToast(message);
  };
  const handleKeyUp = (event,comment,isParent) => {
    if (event.key === 'Enter' && comment !== "" && hasManageLikesCommentPermission) {
      handlePostComment(comment,isParent);
    }
  };
  const youtubeDeleteFailureCallback =  (message,deletedComment, parentComment)  => {
    showToast(message, 'e');
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      if (parentData) {
        parentData.replies.push(deletedComment);
        parentData.comment_count += 1;
      }
    } else {
      commentData.comments.push(deletedComment);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count + 1,
      }));
    }
    setCommentData({ ...commentData });
  }

  const writeContentView = (id, comment, isParent = false) => {
    return (
      <Card
        sx={{
          ...(!isParent && { boxShadow: 'none' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            ...(isParent && {
              position: 'fixed',
              bottom: '0px',
              padding: '25px',
              width: sideBarWidth,
              background: 'white',
            }),
          }}
          sx={{ marginRight: '12rem !important', marginLeft: isParent ? '0px' : '80px !important' }}
        >
          <Avatar
            src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
            alt={profile.profile_name ? profile.profile_name : profile.id}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: 40,
              width: 40,
              bgcolor: (theme) => theme.palette.primary.main,
            }}
            style={{ color: 'white' }}
          />
          <TextField
            fullWidth
            size="small"
            value={comment}
            disabled={!hasManageLikesCommentPermission}
            onKeyUp={(e)=>{
              handleKeyUp(e,comment,isParent)}
            }
            inputRef={isParent ? commentInputRef : childCommentInputRef}
            placeholder="Write a comment…"
            onChange={(e) => {
              handleChangeComment(e, isParent);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmojiPicker handleEmoji={handleEmoji} />
                </InputAdornment>
              ),
            }}
            sx={{
              ml: 2,
              mr: 1,
              opacity: !hasManageLikesCommentPermission ? 0.5 : 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            endIcon={<SendIcon />}
            size=""
            onClick={() => {
              handlePostComment(comment, isParent);
            }}
            disabled={!comment || !hasManageLikesCommentPermission}
            sx={{
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
                opacity: 0.6,
              },
              '&.MuiLoadingButton-root:hover': {
                border: 'none',
                background: 'none',
              },
            }}
          ></LoadingButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Card>
    );
  };

  const handlePostComment = (comment, isParent) => {
    if (comment.length > 10000) {
      showToast(`Comment exceeds the maximum length of 10000 characters. Please shorten your comment. Current length: ${comment.length}`,'e');
      return;  
    }
    setLoading(true);
    let postCommentBody = {
      connected_profile_id: pageId,
      video_id: postId,
      message: comment,
    };
    let postChildCommentBody = {
      connected_profile_id: pageId,
      comment_id: childCommentId,
      message: comment,
    };
    let postProps = {
      url: isParent ? postsModuleURL.addYoutubeComment : postsModuleURL.addYoutubeRepliesComment,
      body: isParent ? postCommentBody : postChildCommentBody,
      successCallback: (data, message) => {
        isParent
          ? getAfterAddCommentCallback(data, message, comment)
          : getAfterAddReplyCallback(data, message, childCommentId, comment);
      },
      failureCallback: youtubePostFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const getAfterAddCommentCallback = (data, message, text) => {
    let newComment = {
      author_name: profile?.profile_name,
      id: data.id,
      author_channel_url: '',
      message: text,
      like_count: 0,
      comment_count: 0,
      created_time: new Date().toISOString(),
      author_profile_image_url: profile?.profile_image,
      pagination: '',
      replies: [],
    };
    const updatedComments = [newComment, ...commentData.comments];
    setPostDetails(prevDetails => ({
      ...prevDetails,
      comment_count: prevDetails.comment_count + 1,
    }));
    // Update the state with the new comments array
    setCommentData((prevCommentData) => ({
      ...prevCommentData,
      comments: updatedComments,
    }));
    message && showToast(message);
    setLoading(false);
    setCommentToInitialState();
  };
  const getAfterAddReplyCallback = (data, message, commentId, text) => {
    let newReply = {
      author_name: profile?.profile_name,
      id: data.id,
      author_channel_url: '',
      message: text,
      like_count: 0,
      created_time: new Date().toISOString(),
      author_profile_image_url: profile?.profile_image,
    };
    const updatedComments = commentData.comments.map((comment) => {
      if (comment.id === commentId) {
        const updatedReplies = [newReply, ...comment.replies];
        return {
          ...comment,
          replies: updatedReplies,
          comment_count: comment.comment_count + 1,
        };
      }
      return comment;
    });

    setCommentData((prevCommentData) => ({
      ...prevCommentData,
      comments: updatedComments,
    }));
    message && showToast(message);
    setLoading(false);
    setCommentToInitialState();
  };
  const youtubePostFailureCallback = (message) => {
    message && showToast(message);
    setLoading(false);
  };
  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Box sx={{ position: 'fixed', width: sideBarWidth, zIndex: 1000, background: 'white' }}>
          <Grid alignItems="center" display="flex" sx={{ml:1.3}}>
            <img alt="Youtube_Icon" width={60} height={60} sx={{ fontSize: 22 }} src={YoutubeIcon} />
            <Typography
              style={{
                fontSize: 25,
                fontWeight: 800,
              }}
            >
              YouTube
            </Typography>
            <a href={postDetails?.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
              <LaunchIcon sx={{ fontSize: '2rem', mr: 2, color: 'primary.main' }} />
            </a>
          </Grid>
          <Divider />
        </Box>
        <Box sx={{ mt: '5.6rem' }}>
          {showLoader ? (
            <Spinner height={height} />
          ) : (
            <>
              <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
                {postContent()}
              </Grid>
              {postComments()}
              {writeContentView(pageId, comment, true)}
            </>
          )}
        </Box>
      </StyledComponent.StyledStack>
    </>
  );
};

//Styled Components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px', // for screens wider than 600px
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '24px', // for screens wider than 960px
    },
  },
}));

export default YoutubePostDetailView;
