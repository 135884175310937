import BrushIcon from '@mui/icons-material/Brush';
import LabelIcon from '@mui/icons-material/Label';
import { useTheme } from '@mui/material/styles';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Helmet } from "react-helmet-async";
import { CommonHelper } from 'src/utils/commonHelper';
import BrandingInfo from './branding-info.component';
import WhiteLabel from './white-label.component';
import CustomEmail from './custom-email.component';

const Branding = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userPermissions = CommonHelper.GetBrandInfoFromLocalStorage("permissions");

    const [currentTab, setCurrentTab] = useState('Branding');
    
    const ACCOUNT_TABS = [
        {
            value: 'Branding',
            icon: <BrushIcon sx={{ width: "20px", height: "20px" }} />,
            component: <BrandingInfo />,
            permissions: [CommonHelper.PermissionList.ORG_ADMIN]
        },
        {
            value: 'White Label',
            icon: <LabelIcon sx={{ width: "20px", height: "20px" }} />,
            component: <WhiteLabel />,
            permissions: [CommonHelper.PermissionList.ORG_ADMIN]
        },
        {
            value: 'Custom Email',
            icon: <LabelIcon sx={{ width: "20px", height: "20px" }} />,
            component: <CustomEmail />,
            permissions: [CommonHelper.PermissionList.ORG_ADMIN]
        },
    ];
    return (
        <>
            <Helmet>
                <title>Branding</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        let isShow = tab.permissions.some(b => userPermissions[b]);
                        return isShow && <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    })}
                </Tabs>

            </Box>
            <Box sx={{ mb: 2 }} />
            {ACCOUNT_TABS.map((tab) => {
                let isShow = tab.permissions.some(b => userPermissions[b]);
                const isMatched = tab.value === currentTab;
                return isShow && isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    );
};

export default Branding;
